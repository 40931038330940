import "core-js/modules/es.array.push.js";
export default {
  async created() {
    await this.$store.dispatch("user/getUser");
    if (this.$store.state.user.user) {
      this.$router.push({
        name: "ProfilePlan"
      });
    } else {
      this.$router.push("/");
    }
  }
};